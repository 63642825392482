import payload_plugin_0l1nYNmOsQ from "/_base/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.1_typescript@5.7.3_vue@3.5.13_typescript@5.7.3___rollup@4.32.0/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_oTJjZC683C from "/app/node_modules/.pnpm/nuxt@3.15.3_@libsql+client@0.14.0_@parcel+watcher@2.5.1_@types+node@22.10.10_better-sql_fd8fa6e89d3511eebb3fc24dc6124ba1/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_g8jknwsHLp from "/app/node_modules/.pnpm/nuxt@3.15.3_@libsql+client@0.14.0_@parcel+watcher@2.5.1_@types+node@22.10.10_better-sql_fd8fa6e89d3511eebb3fc24dc6124ba1/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_4KNymYDTpw from "/app/node_modules/.pnpm/nuxt@3.15.3_@libsql+client@0.14.0_@parcel+watcher@2.5.1_@types+node@22.10.10_better-sql_fd8fa6e89d3511eebb3fc24dc6124ba1/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_t6DIs5gm07 from "/app/node_modules/.pnpm/nuxt-site-config@3.0.6_magicast@0.3.5_rollup@4.32.0_vite@6.0.11_@types+node@22.10.10_ji_35082506265b84ecaf3f074e054179f9/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_XCnSPzH4cQ from "/app/node_modules/.pnpm/nuxt@3.15.3_@libsql+client@0.14.0_@parcel+watcher@2.5.1_@types+node@22.10.10_better-sql_fd8fa6e89d3511eebb3fc24dc6124ba1/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_SVgjxOg3nt from "/app/node_modules/.pnpm/nuxt@3.15.3_@libsql+client@0.14.0_@parcel+watcher@2.5.1_@types+node@22.10.10_better-sql_fd8fa6e89d3511eebb3fc24dc6124ba1/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_4AfVyM6gQp from "/app/node_modules/.pnpm/nuxt@3.15.3_@libsql+client@0.14.0_@parcel+watcher@2.5.1_@types+node@22.10.10_better-sql_fd8fa6e89d3511eebb3fc24dc6124ba1/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_WcJsvT1NTq from "/app/node_modules/.pnpm/nuxt@3.15.3_@libsql+client@0.14.0_@parcel+watcher@2.5.1_@types+node@22.10.10_better-sql_fd8fa6e89d3511eebb3fc24dc6124ba1/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_GtdiPeqywa from "/_base/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.1_typescript@5.7.3_vue@3.5.13_typescript@5.7.3___rollup@4.32.0/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_x3U36X4ntw from "/app/node_modules/.pnpm/nuxt@3.15.3_@libsql+client@0.14.0_@parcel+watcher@2.5.1_@types+node@22.10.10_better-sql_fd8fa6e89d3511eebb3fc24dc6124ba1/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_UHSaLMZSQx from "/_base/node_modules/.pnpm/nuxt-strict-fetch@0.7.2_magicast@0.3.5_rollup@4.32.0/node_modules/nuxt-strict-fetch/dist/runtime/plugin.js";
import switch_locale_path_ssr_odgL2F4XP2 from "/_base/node_modules/.pnpm/@nuxtjs+i18n@9.1.3_@vue+compiler-dom@3.5.13_eslint@9.19.0_jiti@2.4.2__magicast@0.3.5_ro_610354c3484a6cbb771c825d5d6adcc6/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import route_locale_detect_OZHbVzNXwu from "/_base/node_modules/.pnpm/@nuxtjs+i18n@9.1.3_@vue+compiler-dom@3.5.13_eslint@9.19.0_jiti@2.4.2__magicast@0.3.5_ro_610354c3484a6cbb771c825d5d6adcc6/node_modules/@nuxtjs/i18n/dist/runtime/plugins/route-locale-detect.js";
import i18n_oqLMWLj20B from "/_base/node_modules/.pnpm/@nuxtjs+i18n@9.1.3_@vue+compiler-dom@3.5.13_eslint@9.19.0_jiti@2.4.2__magicast@0.3.5_ro_610354c3484a6cbb771c825d5d6adcc6/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import primevue_plugin_egKpok8Auk from "/app/.nuxt/primevue-plugin.mjs";
import plugin_client_j6IKdMlrTI from "/_base/node_modules/.pnpm/@primevue+nuxt-module@4.2.5_@babel+parser@7.26.7_magicast@0.3.5_rollup@4.32.0_vue@3.5.13_typescript@5.7.3_/node_modules/@primevue/nuxt-module/dist/runtime/plugin.client.js";
import pwa_icons_plugin_LnrhIJeMG7 from "/app/.nuxt/pwa-icons-plugin.ts";
import pwa_client_6VA0BaMqrL from "/app/node_modules/.pnpm/@vite-pwa+nuxt@0.10.6_magicast@0.3.5_rollup@4.32.0_vite@6.0.11_@types+node@22.10.10_jit_cb4ada6b47134478d6f9fbd64430e1a9/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import _0_request_6u004rKQEG from "/_base/plugins/0.request.ts";
import nuxt_link_Z6CHffTE6l from "/_base/plugins/nuxt-link.ts";
import user_1TSimXl2Ec from "/_base/plugins/user.ts";
import autoAnimate_4HQGapz9xs from "/app/plugins/autoAnimate.ts";
import showOnVisible_client_48SaagSnTk from "/app/plugins/showOnVisible.client.ts";
import ssg_detect_owzLGrMbJR from "/_base/node_modules/.pnpm/@nuxtjs+i18n@9.1.3_@vue+compiler-dom@3.5.13_eslint@9.19.0_jiti@2.4.2__magicast@0.3.5_ro_610354c3484a6cbb771c825d5d6adcc6/node_modules/@nuxtjs/i18n/dist/runtime/plugins/ssg-detect.js";
export default [
  payload_plugin_0l1nYNmOsQ,
  revive_payload_client_oTJjZC683C,
  unhead_g8jknwsHLp,
  router_4KNymYDTpw,
  _0_siteConfig_t6DIs5gm07,
  payload_client_XCnSPzH4cQ,
  navigation_repaint_client_SVgjxOg3nt,
  check_outdated_build_client_4AfVyM6gQp,
  chunk_reload_client_WcJsvT1NTq,
  plugin_vue3_GtdiPeqywa,
  components_plugin_KR1HBZs4kY,
  prefetch_client_x3U36X4ntw,
  plugin_UHSaLMZSQx,
  switch_locale_path_ssr_odgL2F4XP2,
  route_locale_detect_OZHbVzNXwu,
  i18n_oqLMWLj20B,
  primevue_plugin_egKpok8Auk,
  plugin_client_j6IKdMlrTI,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_6VA0BaMqrL,
  _0_request_6u004rKQEG,
  nuxt_link_Z6CHffTE6l,
  user_1TSimXl2Ec,
  autoAnimate_4HQGapz9xs,
  showOnVisible_client_48SaagSnTk,
  ssg_detect_owzLGrMbJR
]